<template>
    <div>
        <landing-page-header></landing-page-header>
        <main>
            <div>
                <img src="../../assets/home/track_order.jpg" alt="" style="width:100%; object-fit:cover;">
            </div>
            <div class="tracking-area container-fluid">
                <div class="tracking-area-header text-center mb-5">
                    <h3>Track your order</h3>
                </div>
                <div class="row">
                    <div class="col-md-2 d-none d-md-block"></div>
                    <div class="col-md-8">
                        <form @submit.prevent="findOrder">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Order Id" v-model="orderId" required>
                                <div class="input-group-append">
                                    <button class="btn btn-orange btn-lg" type="submit" :disabled="loading"><i class="fa fa-search" v-if="!loading"></i><i class="fa fa-spin fa-spinner" v-else style="color:orange"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
                <div class="track" v-if="order != null">
                    <div class="step" :class="[allStatuses.includes(t.status) ? 'active' : '']" v-for="(t, index) in order.tracks" :key="index">
                        <span class="icon"><i class="fa fa-check"></i></span>
                        <span class="text">{{ t.status.toUpperCase() }} <br> <span class="text-muted small">{{ new Date(t.created_at).toDateString() }}</span> </span>
                    </div>
                    
                    <!-- <div class="step active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span class="text">Order confirmed</span> </div>
                    <div class="step active"> <span class="icon"> <i class="fa fa-user"></i> </span> <span class="text"> Picked by courier</span> </div>
                    <div class="step"> <span class="icon"> <i class="fa fa-truck"></i> </span> <span class="text"> On the way </span> </div>
                    <div class="step"> <span class="icon"> <i class="fa fa-box"></i> </span> <span class="text">Ready for pickup</span> </div>
                    <div class="step"> <span class="icon"> <i class="fa fa-box"></i> </span> <span class="text">Ready for pickup</span> </div>
                    <div class="step"> <span class="icon"> <i class="fa fa-box"></i> </span> <span class="text">Ready for pickup</span> </div> -->
                </div>
                <div class="text-center mt-5" v-if="order == null && orderNotFound == true">
                    <div class="h4">
                        We couldn't find an order with that id
                    </div>
                </div>
            </div>
            
        </main>
        <landing-page-footer></landing-page-footer>
    </div>
</template>
<script>
import header from "./layout/header.vue"
import footer from "./layout/footer.vue"
import instance from '../../presenter/api/network'
export default {
    components: { LandingPageHeader: header, LandingPageFooter: footer },
    data(){
        return {
            order: null,
            orderId: "",
            orderNotFound: false,
            loading: false,
            allStatuses: []
        }
    },
    methods: {
        findOrder(){
            this.loading = true;
            instance.get(`track-order?orderID=${this.orderId}`).then(res => {
                if (res.data.data != ""){
                    this.order = res.data.data;
                    
                    this.orderNotFound = false;
                }else{
                    this.order = null;
                    this.orderNotFound = true;
                }
            }).catch(err => {
                this.orderNotFound = true;
                this.order = null;
            }).finally(() => {
                this.loading = false;
            })
        },
        getAllStatus(){
            instance.get('order-status').then(res => {
                this.allStatuses = res.data.data;
            })
        },
        isStatusActive(status){

        }
    },
    mounted(){
        this.getAllStatus()
    }
    
}
</script>
<style scoped>
main{
    
}

.tracking-area{
    padding-top: 80px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 80px;
    background: white;
}

.tracking-area h3{
    font-size: 2.5rem;
    font-weight: bolder;
    line-height: 1.2;
}

 .track {
     position: relative;
     background-color: #ddd;
     height: 7px;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     margin-bottom: 60px;
     margin-top: 50px
 }

 .track .step {
     -webkit-box-flex: 1;
     -ms-flex-positive: 1;
     flex-grow: 1;
     width: 25%;
     margin-top: -18px;
     text-align: center;
     position: relative
 }

 .track .step.active:before {
     background: #e2591d;
 }

 .track .step::before {
     height: 7px;
     position: absolute;
     content: "";
     width: 100%;
     left: 0;
     top: 18px
 }

 .track .step.active .icon {
     background: #ee5435;
     color: #fff
 }

 .track .icon {
     display: inline-block;
     width: 40px;
     height: 40px;
     line-height: 40px;
     position: relative;
     border-radius: 100%;
     background: #ddd
 }

 .track .step.active .text {
     font-weight: 400;
     color: #000
 }

 .track .text {
     display: block;
     margin-top: 7px
 }

@media only screen and (max-width: 768px){
    .track{
        flex-direction: column;
        height: auto;
        background-color: white;
        margin-top:80px;
    }
    .track .step {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        /* width: initial; */
        margin-top: 0;
        text-align: center;
        position: relative
    }
    .track .step::before {
        height: 100%;
        position: absolute;
        content: "";
        width: 7px;
        left: 36px;
        top: -18px
    }
    .track .text{
        width: 100%;
        margin-left: 60px;
    }
    
}
</style>